/* You can add global styles to this file, and also import other style files */

.container
  display: flex
  justify-content: center
  text-align: center
  flex-direction: column
  align-items: center
  .header
    padding: 50px 0 40px
    input
      font-size: 18px
  table
    text-align: center
    td
      padding: 5px 10px
